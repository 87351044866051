import React, { Component } from 'react';

class LinearGauge extends Component {
    render() {
        const {
            value,
            max = 100,
            width = 300,
            height = 30,
            color = "#4caf50",
            trackColor = "#e0e0e0",
            paddingTop = 0,
            paddingBottom = 0,
            justifyContent = "center",
        } = this.props;

        // Clamp the value between 0 and max
        const clampedValue = Math.min(Math.max(value, 0), max);
        const percentage = (clampedValue / max) * 100;

        return (
            <div
                style={{
                    paddingTop: `${paddingTop}px`,
                    paddingBottom: `${paddingBottom}px`,
                    display: 'flex',
                    justifyContent: justifyContent,
                    alignItems: 'center',
                }}
            >
                <div
                    style={{
                        position: 'relative',
                        width: `${width}px`,
                        height: `${height}px`,
                        backgroundColor: trackColor,
                        borderRadius: `${height / 2}px`,
                        overflow: 'hidden',
                        boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.2)',
                    }}
                >
                    {/* Progress Bar */}
                    <div
                        style={{
                            height: '100%',
                            width: `${percentage}%`,
                            backgroundColor: color,
                            transition: 'width 0.3s ease-in-out',
                            borderRadius: `${height / 2}px 0 0 ${height / 2}px`,
                        }}
                    />
                    {/* Text Centered Inside the Bar */}
                    <div
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: percentage > 50 ? '#fff' : '#000', // Adjust text color based on contrast
                            fontWeight: 'bold',
                            fontSize: '14px',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {`${clampedValue} / ${max}`}
                    </div>
                </div>
            </div>
        );
    }
}

export default LinearGauge;
