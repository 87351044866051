import React, { Component, Fragment } from 'react';
import Papa from "papaparse";
import { Map, List, Record } from 'immutable';
import axios from 'axios';
import {
    Table,
    Container, Row, InputGroupText, InputGroup, Col, Button, Form, FormText,

    FormGroup, Label, Input
} from 'reactstrap';
import HeaderComponent from '../header/HeaderComponent'



function statusChange(type) {
    const { data } = this.state;
    this.setState(
        { data: data.set("currentSlamState", type) }
    );
}


class CSVImport extends Component {
    constructor(props) {
        super(props)
        this.state = {


            falprompt: "",
            prompt: "",

        }
        statusChange = statusChange.bind(this);

        this.test = React.createRef()
    }



    handleSubmit = async () => {


        await axios({
            method: 'put',
            url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/admin/charan/prompt`,

            data: {
                falprompt: this.state.falprompt,
                prompt: this.state.prompt,
            },
            headers: {
                Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
            },
        });
        alert("업데이트 완료")
    }

    onValueChanged = (name, value) => {
        let obj = {};
        obj[name] = value;
        this.setState(obj);
    }
    async componentDidMount() {
        const { data } = this.state;
        await this.getPrompt()

    }
    getPrompt = async () => {
        const result = await axios({
            method: "Get",
            url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/admin/charan/prompt`,
            params: {

            },
            headers: {
                Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
            },
        });
        const data = JSON.parse(result.data.body);


        this.setState({ prompt: data.data.prompt_1, falprompt: data.data.prompt_2 });
    }

    render() {


        return (
            <Fragment>
                <HeaderComponent signOut={this.props.cognito.signOut} setStage={this.props.setStage} stage={this.props.stage}></HeaderComponent>
                <div className="main_contents">
                    <Container fluid>
                        <Row>
                            <div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                차란의 기본 프롬프트를 업데이트 합니다.
                            </div>


                            <div className="col-md-12" id="time_c1">
                                <div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>

                                    <Form className="col-md-6">
                                        <FormGroup row>
                                            <Label
                                                for="prompt"
                                                sm={2}
                                            >
                                                prompt
                                            </Label>
                                            <Col sm={10}>
                                                <textarea
                                                    style={{ height: "250px" }}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="prompt"
                                                    value={this.state.prompt}
                                                    onChange={(val) => {
                                                        this.onValueChanged("prompt", val.target.value);
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label
                                                for="fal prompt"
                                                sm={2}
                                            >
                                                fal prompt
                                            </Label>
                                            <Col sm={10}>
                                                <textarea
                                                    style={{ height: "250px" }}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="fal prompt"
                                                    value={this.state.falprompt}
                                                    onChange={(val) => {
                                                        this.onValueChanged("falprompt", val.target.value);
                                                    }}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Form>

                                </div>

                            </div>
                            <div> <a
                                style={{ fontWeight: 800, color: "red" }}
                            >{this.state.currentPartnum > 0 ? `업로드중 : ${this.state.currentPartnum + 1}/${this.state.totalPartNum}` : ""}</a></div>
                            <div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                <button type="submit" className="btn btn-primary" onClick={() => { this.handleSubmit() }}>
                                    업로드
                                </button>

                            </div>

                        </Row>
                    </Container>
                </div>

            </Fragment >
        );
    }
}

export default CSVImport;
