import React, { Component, Fragment } from "react";



import axios from "axios";

import { Table, Container, Row, InputGroup, Col, Navbar, NavbarBrand, Nav, NavItem, NavLink, Card, CardBody, CardTitle, CardSubtitle, CardText, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ButtonGroup, FormGroup, Label, Input } from "reactstrap";
import moment from "moment";
import HeaderComponent from "../header/HeaderComponent";
import Select from "react-select";

import $ from "jquery";
import "datatables.net";
import { is } from "immutable";
import TaskComponent from "../element/TaskComponent";
import TagComponent from "../element/TagComponent";

const { Base64 } = require("js-base64");

function statusChange(type) {
	const { data } = this.state;
	this.setState({ data: data.set("currentSlamState", type) });
}

function formatBytes(bytes, decimals = 2) {
	if (!+bytes) return "0 Bytes";

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}
class ProductAllPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedMode: "all",
			showModal: false,
			tableData: [],
			project_list: [],
			selectedData: undefined,
			max_rows: 25,
			taskList: [],
			taskTags: [],
			modalImageSrc: '',
			modalImageSrc2: '',
			selectedProjectName: "",
			currentSelectedTask: undefined,
			selectedProjectName: "all",

		};
		statusChange = statusChange.bind(this);
		this.mqttClient = "";
		this.test = React.createRef();
		this.modalRef = React.createRef();
		window.that = this;
	}







	toggleModal = (item) => {

		this.setState({ showModal: !this.state.showModal });
	};


	onEvalChanged = async (seq, value) => {
		const { data } = this.state;
		const result = await axios({
			method: "Post",
			url: `${this.props.urls[this.props.stage]}/admin/request`,
			data: {
				seq: seq,
				evaluation: value,
			},
			headers: {
				Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
			},
		});
		alert("변경했습니다.");
		this.doShowModal(Base64.encode(JSON.stringify(result.data.data)));
	};
	onValueChanged = (name, value) => {

		console.log("name:", name, ",value:", value)

		this.setState({
			[name]: value

		}, () => {
			if (name == "selectedMode") {
				this.initializeDatatable();
			}
			if (name == "selectedProjectName") {
				this.initializeDatatable();
			}
		});

	};
	handleClickOutside = (event) => {
		if (this.modalRef.current && !this.modalRef.current.contains(event.target)) {
			this.closeModal();
		}
	};
	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClickOutside);
	}
	getEsmCall = async () => {

		await axios({
			url: `${process.env.REACT_APP_API_PATH}/prod/admin/esm`,
			params: {

			},
			type: "get",
			headers: {
				Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
			},
			error: function (xhr, error, code) {
				console.log(error);
			},
		});



	};
	getDataTableAjax = () => {
		const { data } = this.state;

		let mode = this.state.selectedMode

		if (mode == "diverged") {
			mode = "all";
		}
		return {
			url: `${process.env.REACT_APP_API_PATH}/prod/admin/project/product/datatable?status=${this.state.selectedMode}&project_name=${this.state.selectedProjectName}`,
			type: "get",
			headers: {
				Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
			},
			error: function (xhr, error, code) {
				alert("권한이 없습니다.");
			},
		};
	};

	getTaskInfo = async (seq) => {



		const _result = await axios({
			url: `${process.env.REACT_APP_API_PATH}/prod/admin/task/info`,
			params: {
				seq: seq,
			},
			type: "get",
			headers: {
				Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
			},
			error: function (xhr, error, code) {
				alert("권한이 없습니다.");
			},
		});
		const result = JSON.parse(_result.data.body);
		this.setState({
			currentTask: result.task,
			currentEnvParm: result.parmenv,
			isInfoModalOpen: true,
		})
	};
	projectCheck = (project_name, type) => {

		if (type === "all") {
			window.open("/product?project_name=" + project_name, "_blank");
		} else {
			window.open("/productone?project_name=" + project_name, "_blank");
		}
	}
	generateImageUrl(item, thumnail) {
		//console.log(item);
		if (!item) {
			return "";
		}
		if (thumnail) {
			return `${process.env.REACT_APP_API_PATH}/prod/public/download/image?project_name=${item.project_name}&seq=${item.seq1}&type=input&version=${0}&width=500&height=500`

		}
		return `${process.env.REACT_APP_API_PATH}/prod/public/download/image?project_name=${item.project_name}&seq=${item.seq1}&type=input&version=${0}`
	}



	generateResultUrl(item, thumnail) {

		if (!item) {
			return "";
		}
		if (thumnail) {
			return `${process.env.REACT_APP_API_PATH}/prod/public/download/image?project_name=${item.project_name}&seq=${item.seq1}&type=output&width=500&height=500`
		}
		return `${process.env.REACT_APP_API_PATH}/prod/public/download/image?project_name=${item.project_name}&seq=${item.seq1}&type=output`
	}
	generateImageUrl2(item, thumnail) {
		console.log(item);
		if (!item) {
			return "";
		}
		if (thumnail) {
			return `${process.env.REACT_APP_API_PATH}/prod/public/download/image?project_name=${item.project_name}&seq=${item.seq}&type=input&version=${item.version}&width=500&height=500`

		}
		return `${process.env.REACT_APP_API_PATH}/prod/public/download/image?project_name=${item.project_name}&seq=${item.seq}&type=input&version=${item.version}`
	}



	generateResultUrl2(item, thumnail) {

		if (!item) {
			return "";
		}
		if (thumnail) {
			return `${process.env.REACT_APP_API_PATH}/prod/public/download/image?project_name=${item.project_name}&seq=${item.seq}&type=output&width=500&height=500`
		}
		return `${process.env.REACT_APP_API_PATH}/prod/public/download/image?project_name=${item.project_name}&seq=${item.seq}&type=output`
	}
	getCurrentStatus = (status) => {
		if (status == "accept") {
			return `<span style="font-weight:bold; color:green">수락</span>`
		}
		else if (status == "hold") {
			return `<span  style="font-weight:bold; color:orange" }}>보류</span>`
		}
		else if (status == "fail") {
			return `<span style="font-weight:bold; color:red">제외</span>`
		}
		else {
			return `<span  style="font-weight:bold; color:purple">검수 전</span>`
		}
	}
	openModal = async (jsonstr) => {
		const item = JSON.parse(jsonstr);


		this.setState({ currentItem: item, isModalOpen: true, modalImageSrc: this.generateImageUrl(item), modalImageSrc2: this.generateResultUrl(item) }, () => {
			this.getProductTask(item.product_id, item.project_name);
		},);

	}


	// Close the modal
	closeModal = () => {
		console.log("modal closed");
		this.setState({ isModalOpen: false, modalImageSrc: '', isRejctModelOpen: false, rejectTargetIdx: undefined, isShowRejectReasonOpen: false });
	};
	getProductTask = async (product_id, project_name) => {
		const result = await axios({
			method: "Get",
			url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/admin/project/product/task`,
			params: {
				product_id: product_id,
				project_name: project_name,
			},
			headers: {
				Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
			},
		});
		const tasks = JSON.parse(result.data.body).tasks;


		this.setState({ taskList: tasks }, () => {

			if (this.state.currentItem.seq1 == undefined) {
				this.setState({ currentSelectedTask: tasks[0] });
			}
			else {
				//find the task with the same seq1
				let targetTask = tasks.find((task) => task.seq == this.state.currentItem.seq1);
				this.setState({ currentSelectedTask: targetTask });
			}


		});
	}
	getColumnSetting = () => {
		const that = this;
		const thumbnailWidth = 75;
		const thumbnailHeight = 75;



		let idx = { title: "idx", data: "idx", searchable: true, className: "dt-center", width: "5%" };

		let project_name = { title: "project_name", data: "project_name", searchable: true, className: "dt-center", width: "5%" };
		let product_id = { title: "project_name", data: "product_id", searchable: true, className: "dt-center", width: "5%" };
		let original_view = {
			title: "원본",
			data: "seq",
			className: "dt-center",
			render: function (data, type, row, meta) {
				//	return `<img  role="button" width=${500} height=${500} onclick="window.that.downloadFile('${row.seq}')"  src='${that.generateImageUrl(row)}'>`;

				return `<img  role="button" width=${500} height=${500}  src='${that.generateImageUrl(row)}'>`;


			},
		};
		let result_view = {
			title: "결과보기",
			data: "seq",
			className: "dt-center",
			render: function (data, type, row, meta) {

				return `<img  role="button" width=${500} height=${500} onclick='window.that.openModal("${JSON.stringify(row).replaceAll('"', '\\"')}")'   src='${that.generateResultUrl(row)}'>`;


			},
		};
		let status = {
			title: "상태",
			data: "seq",
			className: "dt-center",
			render: function (data, type, row, meta) {

				return that.getCurrentStatus(row.check_status)


			},
		};
		let customer_status = {
			title: "고객 검수 상태",
			data: "seq",
			className: "dt-center",
			render: function (data, type, row, meta) {

				return that.getCurrentStatus(row.customer_check_status)


			},
		};

		let arr = [idx, project_name, product_id, original_view, result_view, status, customer_status];





		return arr;
	};
	updateProductStatus = async (productIdx, status) => {
		this.setState({ isLoading: true });
		try {
			await axios({
				method: "Post",
				url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/admin/project/product/update`,
				data: {
					product_idx: productIdx + "",
					check_status: status

				},
				headers: {
					Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
				},
			});
			this.closeModal();
			//this.initializeDatatable();
		} catch (e) {
			//alert(e)
			return;
		}




	};
	initializeDatatable = () => {
		const that = this;
		const { data } = this.state;
		if (this.oTable) {
			this.oTable.destroy();
			this.test.current.innerHTML = "";
		}
		this.oTable = $(this.test.current).DataTable({
			order: [[0, "desc"]],
			iDisplayLength: this.state.max_rows,
			searching: true,
			processing: true,
			responsive: true,
			language: {
				processing: "Loading...",
			},
			serverSide: true,
			ajax: this.getDataTableAjax(),
			columns: this.getColumnSetting(),
			initComplete: function () {
				$(".dataTables_filter input").unbind();
				$(".dataTables_filter input").bind("keyup", function (e) {
					var code = e.keyCode || e.which;
					if (code == 13) {
						that.oTable.search(this.value).draw();
					}
				});
			},
		});
		$(".dataTables_wrapper").css("width", "100%");
		$("#DataTables_Table_0_length").on("change", function (val) {
			that.setState({ data: data.set("max_rows", parseInt($("#DataTables_Table_0_length  option:selected").text())) });
		});
	};
	refreashTable = (event) => {
		this.initializeDatatable();
	};
	getProjectList = async () => {
		const result = await axios({
			method: "Get",
			url: `${process.env.REACT_APP_API_PATH}/prod/admin/project/list`,
			params: {


			},
			headers: {
				Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
			},
		});
		const projects = JSON.parse(result.data.body).project_list;
		if (projects == undefined) {
			return;
		}
		projects.unshift({ project_name: "all" })
		const searchParams = new URLSearchParams(window.location.search);
		const projectName = searchParams.get("project_name");

		this.setState({
			project_list: projects,
			selectedProjectName: (projectName) ? projectName : "all"
			//selectedProjectName: "rlabs_241118"
		}, () => {

			this.initializeDatatable();


		});
	}
	async componentDidMount() {
		document.addEventListener("mousedown", this.handleClickOutside);
		this.getProjectList()


	}
	getModeKor = () => {

		return "전체 목록"
	};


	handleResultClick = (project_name, seq, type) => {
		let url = "";
		if (type == "input") {
			url = this.generateImageUrl(project_name, seq);
		} else if (type == "model") {
			url = this.generateModelUrl(project_name, seq);
		} else if (type == "output") {
			url = this.generateResultUrl(project_name, seq);
		}

		this.setState({ isModalOpen: true, modalImageSrc: url });

	};
	getCurrentTile = (status) => {
		if (status == "accept") {
			return (<h2 style={{ fontWeight: "bold", color: "green" }}>현재상태 : 확정</h2>)
		}
		else if (status == "hold") {
			return (<h2 style={{ fontWeight: "bold", color: "orange" }}>현재상태 : 보류</h2>)
		}
		else if (status == "fail") {
			return (<h2 style={{ fontWeight: "bold", color: "red" }}>현재상태 : 실패</h2>)
		}
		else {
			return (<h2 style={{ fontWeight: "bold", color: "purple" }}>현재상태 : 검수 전</h2>)
		}
	}
	generateImageUrl(item, thumnail) {
		if (!item) {
			return "";
		}
		if (thumnail) {
			return `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/public/download/image?project_name=${item.project_name}&seq=${item.seq1}&type=input&version=${item.version}&width=500&height=500`

		}
		return `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/public/download/image?project_name=${item.project_name}&seq=${item.seq1}&type=input&version=${item.version}`
	}

	generateModelUrl(item, thumnail) {
		if (!item) {
			return "";
		}
		if (thumnail) {
			return `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/public/download/image?project_name=${item.project_name}&seq=${item.seq}&type=output&version=${item.version}&width=500&height=500`
		}
		return `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/public/download/image?project_name=${item.project_name}&seq=${item.seq}&type=output&version=${item.version}`
	}

	generateResultUrl(item, thumnail) {
		if (!item) {
			return "";
		}
		if (thumnail) {
			return `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/public/download/image?project_name=${item.project_name}&seq=${item.seq1}&type=output&width=500&height=500`
		}
		return `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/public/download/image?project_name=${item.project_name}&seq=${item.seq1}&type=output`
	}
	productReRun = async (product_idx, product_id) => {
		try {
			const result = await axios({
				method: "Put",
				url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/admin/project/product/rerun`,
				data: {
					product_idx: product_idx + ""

				},
				headers: {
					Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
				},
			});

			alert("추가 요청이  처리되었습니다. 잠시 기다려주세요(검수 전 상태로 변경됩니다.)");
			this.getProductTask(product_id, this.state.currentItem.project_name);
		} catch (e) {
			alert(e);
		}
		this.updateProductStatus(this.state.currentItem.idx, "ready");
	}

	setCurrentSelectedTask = (task) => {
		this.setState({ currentSelectedTask: task });
	}
	updateMainTask = async (productIdx, seq) => {

		try {
			await axios({
				method: "Post",
				url: `${process.env.REACT_APP_API_PATH}/${process.env.REACT_APP_STAGE}/admin/project/product/update`,
				data: {
					product_idx: productIdx + "",
					seq: seq

				},
				headers: {
					Authorization: this.props.cognito.user.signInUserSession.idToken.jwtToken,
				},
			});
		} catch (e) {
			//alert(e)
			return;
		}

		this.setState((prevState) => ({

			currentItem: {
				...prevState.currentItem,
				seq1: seq
			}
		}));

	};
	render() {
		const selectedData = this.state.currentTask;


		//<TableBox options={options} sizeClass={"col-md-6"}></TableBox>
		return (
			<Fragment>
				{this.state.isModalOpen && (
					<div className="modals"
						zIndex={1500}
						style={{

						}} >
						<div className="modal-contents" ref={this.modalRef} style={{ width: "100%", height: "95%" }}>
							<Row>
								<Col md={2}>
									<h2>{`${this.state.currentItem.project_name} : ${this.state.currentItem.product_id}`}</h2>
								</Col>
								<Col md={4}>
									{this.getCurrentTile(this.state.currentItem.check_status)}
								</Col>
								<Col md={6} style={{ alignContent: "end", textAlign: "end" }}>
									<button onClick={this.closeModal} style={{
										background: 'none',
										border: 'none',
										cursor: 'pointer',
										fontSize: '30px',
										color: '#999',
									}}>
										&times;
									</button>
								</Col>
							</Row>
							<Row>
								<Col
									style={{}}
									md={3}>
									<img onClick={() => { this.closeModal() }} style={{

										display: "block",
										"margin-left": "auto",
										"margin-right": "auto",
										"object-fit": "contain",
										"max-height": 800,
										"max-width": 500
									}} src={this.state.modalImageSrc} alt="Large version" className="modal-image" />
								</Col>
								<Col
									style={{ padding: "5px" }}
									md={5}>
									<img onClick={() => { this.closeModal() }} style={{
										display: "block",
										border: "1px solid black",
										"margin-left": "auto",
										"margin-right": "auto",
										"object-fit": "contain",
										"max-height": 800
									}} src={this.generateModelUrl(this.state.currentSelectedTask)} alt="Large version" className="modal-image" />


								</Col>
								<Col
									style={{
										display: "flex", // Enable Flexbox
										flexWrap: "wrap", // Allow wrapping to the next row
										justifyContent: "left", // Align items horizontally
										"align-content": "flex-start",
										"column-gap": "0px",
										"row-gap": "1px",

									}}
									md={2}>

									{
										this.state.taskList.map((item, index) => (

											<TaskComponent item={item} onClick={this.setCurrentSelectedTask}
												isSelected={this.state.currentSelectedTask?.seq == item.seq}
												isCurrent={this.state.currentItem?.seq1 == item.seq}
											></TaskComponent>
										))
									}

								</Col>
								<Col

									style={{}}
									md={2}>
									<Row>
										<table style={{ width: "100%", border: "1px solid black", "border-collapse": "collapse" }}>
											<thead>
												<tr>
													<th style={{ width: "30%" }}>Prop</th>
													<th style={{ width: "50%" }}>Value</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td className="tb_td">seq</td>
													<td className="tb_td">{this.state.currentSelectedTask?.seq}</td>
												</tr>
												<tr>
													<td className="tb_td">카테고리</td>
													<td className="tb_td">{this.state.currentSelectedTask?.category_1}</td>
												</tr>
												<tr>
													<td className="tb_td">Total</td>
													<td className="tb_td">{this.state.currentSelectedTask?.size_total}</td>
												</tr>
												<tr>
													<td className="tb_td">Hip</td>
													<td className="tb_td">{this.state.currentSelectedTask?.size_hip}</td>
												</tr>

												<tr>
													<td className="tb_td">Waist</td>
													<td className="tb_td">{this.state.currentSelectedTask?.size_waist}</td>
												</tr>
												<tr>
													<td className="tb_td">Breast</td>
													<td>{this.state.currentSelectedTask?.size_breast}</td>
												</tr>
												<tr>
													<td className="tb_td">Sleeve</td>
													<td className="tb_td">{this.state.currentSelectedTask?.size_sleeve}</td>
												</tr>
												<tr>
													<td className="tb_td">Shoulder</td>
													<td className="tb_td">{this.state.currentSelectedTask?.size_shoulder}</td>
												</tr>

												<tr>
													<td className="tb_td">(AI)로고 및 텍스트 평가</td>
													<td className="tb_td"
														style={{
															fontWeight: "bold",
															color: (parseFloat(this.state.currentSelectedTask?.ai_eval_logo_letter) >= 9.0) ? "green" : "red"
														}}
													>{this.state.currentSelectedTask?.ai_eval_logo_letter}
													</td>
												</tr>
												<tr>
													<td className="tb_td"
													>(AI)손 평가</td>
													<td className="tb_td"
														style={{
															fontWeight: "bold",
															color: (parseFloat(this.state.currentSelectedTask?.ai_eval_hands) >= 9.0) ? "green" : "red"
														}}>{this.state.currentSelectedTask?.ai_eval_hands}
													</td>
												</tr>
												<tr>
													<td className="tb_td">(AI)포즈</td>
													<td className="tb_td"
														style={{
															fontWeight: "bold",
															color: (parseFloat(this.state.currentSelectedTask?.ai_eval_representation) >= 9.0) ? "green" : "red"
														}}>{this.state.currentSelectedTask?.ai_eval_representation}
													</td>
												</tr>
												<tr>
													<td className="tb_td">(AI)General</td>
													<td className="tb_td"
														style={{
															fontWeight: "bold",
															color: (parseFloat(this.state.currentSelectedTask?.ai_eval_resemblance) >= 9.0) ? "green" : "red"
														}}>{this.state.currentSelectedTask?.ai_eval_resemblance}
													</td>
												</tr>
												<tr>
													<td className="tb_td">(AI)전체 평균</td>
													<td className="tb_td"
														style={{
															fontWeight: "bold",
															color: (((parseFloat(this.state.currentSelectedTask?.ai_eval_resemblance) +
																parseFloat(this.state.currentSelectedTask?.ai_eval_representation) +
																parseFloat(this.state.currentSelectedTask?.ai_eval_hands) +
																parseFloat(this.state.currentSelectedTask?.ai_eval_logo_letter)) / 4) >= 9.0) ? "green" : "red"
														}}>{
															((parseFloat(this.state.currentSelectedTask?.ai_eval_resemblance) +
																parseFloat(this.state.currentSelectedTask?.ai_eval_representation) +
																parseFloat(this.state.currentSelectedTask?.ai_eval_hands) +
																parseFloat(this.state.currentSelectedTask?.ai_eval_logo_letter)) / 4).toFixed(2)
														}
													</td>
												</tr>
												{/* <tr>
												<td className="tb_td">사람 평가</td>
												<td className="tb_td"
													style={{
														fontWeight: "bold",
														color: (this.state.currentSelectedTask?.check_status == "accept") ? "green" : "red"
													}}

												>{this.state.currentSelectedTask?.check_status}</td>
											</tr> */}
											</tbody>
										</table>
										<div>
											{
												this.state.taskTags.map((item, index) => (
													<TagComponent name={item.name} onRemove={() => {
														this.removeTag(item.idx, this.state.rejectTargetIdx);
													}}></TagComponent>
												))
											}
										</div>
										<Col md={12} style={{ textAlign: "right" }}>


											<Row>
												<Col md={12}>
													<Button
														color="success"
														onClick={() => {
															this.updateMainTask(this.state.currentItem.idx, this.state.currentSelectedTask.seq);
														}}
													>
														이미지고정
													</Button>

													<Button
														color="primary"
														onClick={() => {

															this.updateProductStatus(this.state.currentItem.idx, "accept");
														}}
													>
														확정
													</Button>

													<Button
														color="warning"
														onClick={() => {
															this.updateProductStatus(this.state.currentItem.idx, "hold");
														}}
													>
														보류
													</Button>

													<Button
														color="danger"
														onClick={() => {
															this.updateProductStatus(this.state.currentItem.idx, "fail");
														}}
													>
														실패
													</Button>
												</Col>

											</Row>
											<Row style={{ marginTop: 20 }}>
												<Col md={12}>
													<Button
														color="info"
														onClick={() => {
															this.productReRun(this.state.currentItem.idx, this.state.currentItem.product_id);
														}}
													>
														후보추가
													</Button>

													<Button
														color="success"
														onClick={() => {
															this.getProductTask(this.state.currentItem.product_id, this.state.currentItem.project_name);
														}}
													>
														이미지 새로고침
													</Button>
													{/* <Button
														color="info"
														onClick={() => {
															this.mixModalOpen();
														}}
													>
														Mix
													</Button> */}

												</Col >
											</Row>
											<Row style={{ marginTop: 20 }}>
												<Col md={12}>
													{/* 
													<Button
														color="primary"
														onClick={() => {
															this.refreshData();
														}}
													>
														다음
													</Button> */}
												</Col >

											</Row>

										</Col>

									</Row>
									<Row>

									</Row>
								</Col>


							</Row>
						</div>

					</div>)
				}
				<HeaderComponent signOut={this.props.cognito.signOut} jwtToken={this.props.cognito.user.signInUserSession.idToken.jwtToken} setStage={this.props.setStage} stage={this.props.stage}></HeaderComponent>
				<div className="main_contents">
					<Container fluid>
						<Row>
							<div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
								<Col md={4}>


								</Col>

								<Col md={4} style={{ alignItems: "center", display: "flex", fontSize: 25, color: "black", fontWeight: 800, justifyContent: "center" }}>
									{this.getModeKor()}
								</Col>

								<Col md={4}>

									<Col md={4}>
										<ButtonGroup>
											<Button
												color="info"
												onClick={() => {
													this.initializeDatatable();
												}}
											>
												Refresh
											</Button>
										</ButtonGroup>
									</Col>
									<Col md={4}>
										<Input value={this.state.selectedProjectName} onChange={(e) => this.onValueChanged("selectedProjectName", e.target.value)} id="selectedProjectName" name="selectedProjectName" type="select">

											{this.state.project_list.map((item) => (
												<option value={item.project_name}>{item.project_name}</option>
											)
											)}


										</Input>
									</Col>

									<Col md={4}>
										<Input value={this.state.selectedMode} onChange={(e) => this.onValueChanged("selectedMode", e.target.value)} id="selectedMode" name="selectedMode" type="select">
											<option value={"all"}>목록 선택(전체보기)</option>
											<option value={"accept"}>수락</option>
											<option value={"fail"}>제외</option>
											<option value={"ready"}>검수대기</option>



										</Input>
									</Col>
								</Col>
							</div>
							<div className="x_panel" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
								<Table bordered style={{ width: "100%" }} innerRef={this.test} />
							</div>
						</Row>
					</Container>
				</div>
			</Fragment>
		);
	}
}

export default ProductAllPage;
